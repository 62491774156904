import React from "react"
import "../css/pageSection.css"
import { motion } from "framer-motion"
import { useInView } from "react-intersection-observer";

const PageSection = ({ background, children }) => {

    const [ref, isVisible] = useInView({ threshold: 0.3 });
    const variants = {
        visible: {
            opacity: 1,
            y: 0
        },
        hidden: {
            opacity: 1,
            y: 100
        },
    };
    return (
        <section style={{ backgroundColor: background }}>
            <motion.div
                ref={ref}
                variants={variants}
                animate={isVisible ? "visible" : "hidden"}
                transition={{ duration: 1, ease: "easeInOut" }}
                className={"container"}>
                {children}
            </motion.div>
        </section>
    )
}

export default PageSection