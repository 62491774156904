export default [
    {
        path: "/",
        text: "home",
    },
    {
        path: "/portfolio",
        text: "portfolio",
    },
    {
        path: "/blog",
        text: "blog",
    },
    {
        path: "/#contact",
        text: "contact",
    },
]
