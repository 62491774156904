import React from "react"
import {graphql, useStaticQuery} from "gatsby"
import styles from "../css/footer.module.css"
import {
    FaBehance,
    FaFacebook,
    FaGithub,
    FaInstagram,
    FaLinkedin,
    FaMedium,
    FaPinterest,
    FaQuestion,
    FaStackOverflow,
    FaTwitter,
    FaYoutube
} from 'react-icons/fa';

const Footer = () => {
    const {allMarkdownRemark: {edges}} = useStaticQuery(pageQuery);
    return (
        <footer>
            {edges.map(({node}) => {
                const listItems = node.frontmatter.socialIcons.map((socialIcon) =>
                    <li key={socialIcon.id}>
                        <a href={socialIcon.url} className={styles.icon}>
                            {
                                {
                                    'twitter': <FaTwitter/>,
                                    'linkedin': <FaLinkedin/>,
                                    'instagram': <FaInstagram/>,
                                    'facebook': <FaFacebook/>,
                                    'youtube': <FaYoutube/>,
                                    'github': <FaGithub/>,
                                    'stackoverflow': <FaStackOverflow/>,
                                    'medium': <FaMedium/>,
                                    'pinterest': <FaPinterest/>,
                                    'behance': <FaBehance/>,
                                    'tikok': <FaQuestion/>, //non-existent
                                    default: <FaQuestion/>
                                }[socialIcon.name]
                            }
                        </a>
                    </li>
                );

                return (
                    <div key={node.id}>
                        <ul className={styles.socialLinks}>{listItems}</ul>
                        Copyright © {new Date().getFullYear()} CasparGeerlings.com. All rights reserved.
                    </div>
                )
            })}
        </footer>
    )
}

export const pageQuery = graphql`
    query {
        allMarkdownRemark(filter: {fileAbsolutePath: {regex : "\\/sections/footer/"} }) {
            edges {
                node {
                    id
                    frontmatter {
                        footerImage
                        socialIcons{
                            name
                            url
                        }
                    }
                }
            }
        }
    }
`

export default Footer