import React, {useState} from "react"
import links from "../constants/links"
import styles from "../css/navbar.module.css"
import {Link} from "gatsby"
import {FaAlignRight} from "react-icons/fa"
import logo from "../images/logo.svg"

const Navbar = () => {
    const [isOpen, setNav] = useState();
    const toggleNav = () => {
        setNav(isOpen => !isOpen)
    };

    return (
        <nav className={styles.navbar}>
            <div className={styles.navCenter}>
                <div className={styles.navHeader}>
                    <Link to={"/"}><img src={logo} alt="Caspar Geerlings"/></Link>
                    <button type="button" className={styles.logoBtn} onClick={toggleNav}>
                        <FaAlignRight className={styles.logoIcon}/>
                    </button>
                </div>
                <ul className={isOpen ? `${styles.navLinks} ${styles.showNav}` : `${styles.navLinks}`}>
                    {links.map((item, index) => {
                        return (
                            <li key={index}>
                                <Link fade to={item.path}>
                                    {item.text}
                                </Link>
                            </li>
                        )
                    })}
                </ul>
            </div>
        </nav>
    )
}

export default Navbar