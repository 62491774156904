import React from "react"
import Navbar from "./Navbar"
import Footer from "./Footer"
import "../css/layout.css"
import { motion } from "framer-motion"

export const Layout = ({ children }) => {

    return (
        <div className="root">
            <motion.main
                initial={{ opacity: 0, x: 0 }}
                animate={{ opacity: 1, x: 0 }}
                exit={{ opacity: 0, x: 0 }}
                transition={{ type: "spring", duration: 5, ease: "easeInOut" }}
            >
                <main>
                    <Navbar />
                    {children}
                    <Footer />
                </main>
            </motion.main>
        </div>
    )
};

export default Layout